<template>
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
      <c-card title="법규별 규제사항" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-12 divchklaw" v-for="(item, idx) in header" :key="idx">
            <div class="lawspan">{{item.label}}</div>
            <q-checkbox
              v-for="(_item, _idx) in item.child" :key="_idx"
              dense
              color="orange-7"
              :label="_item.label"
              true-value="Y"
              false-value="N"
              :value="_item.vals"
              class="customqcbox"
              :disable="true"
            />
          </div>
        </template>
      </c-card>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'chem-law',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantName: '',
        plantCd: '',
        materialCd: '',
        materialName: '',
        isSaved: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      headerUrl: '',
      header: [],
    };
  },
  watch: {
    'param.isSaved'() {
      this.getHeader();
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.headerUrl = selectConfig.mdm.mam.chem.header.value.url;
      this.getHeader();
    },
    getHeader() {
      this.$http.url = this.headerUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.header = _result.data;
      },);
    },
  }
};
</script>
<style>
  .lawspan {
    color: #3199c5 !important;
    font-size: 13px !important;
    font-weight: 600;
  }
  .divchklaw {
    margin-bottom: 10px;
  }
</style>>
